import ConditionBuilder from "@/includes/logic/Modules/Builders/ConditionBuilder";
import UserTypeCondition from "./UserTypeCondition";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export default class UserTypeConditionBuilder extends ConditionBuilder {
  icon: string = 'workspace_premium';
  model!: UserTypeCondition;
  view: VueComponent = () => import('../../../../../../components/Modules/conditions/UserTypeCondition/UserTypeConditionView.vue');
}
