import { IterableItemFactory } from "@/components/TriggerSetup/logic/factories/iterable-item-factory";
import { IterableListItem } from "@/components/TriggerSetup/logic/types/types";
import ModuleConfig from "@/includes/logic/Modules/models/ModuleConfig";
import ConditionsBuilderFactory from "@/includes/logic/Modules/Factories/ConditionsBuilderFactory";
import ConditionBuilder from "@/includes/logic/Modules/Builders/ConditionBuilder";
import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";
import UsersCondition from "@/includes/logic/Modules/models/conditions/UsersCondition/UsersCondition";
import store from "@/store/store";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";

export default class ConditionsConfigService {

  iterableItemFactory = new IterableItemFactory()

  frontendConditions: Array<IterableListItem<Array<IterableListItem<ConditionBuilder>>>> = []

  usersInfoService = new GetUserInfoServiceImpl(store.state.networksState.currentNetwork?.chats?.[0] ?? store.getters.chatId)

  constructor(conditions: ModuleConfig['conditions']) {
    this.frontendConditions = this.makeFrontendConditions(conditions)

    const ids = this.findUsersConditions(conditions)

    if (ids.length) {
      this.usersInfoService.getUsersInfos(ids)
    }
  }

  findUsersConditions(conditions: ModuleConfig['conditions']) {
    return conditions.reduce((acc: Array<number>, value) => {

      const cnd = value.filter(c => c instanceof UsersCondition) as Array<UsersCondition>

      if (cnd) {
        acc.push(...cnd.map(c => c.values.items).flat())
      }

      return acc
    }, [])
  }

  makeFrontendConditions(conditions: ModuleConfig['conditions']) {
    return conditions.map(c => this.iterableItemFactory.create(c.map(i => {
      const conditionBuilder = ConditionsBuilderFactory.getCondition(i.type)

      conditionBuilder.setBuilderModel(i)

      return this.iterableItemFactory.create(conditionBuilder)
    })))
  }

  makeCleanConditions() {
    return this.frontendConditions.map(g => g.value.map(c => c.value.model))
  }

  removeCondition(guidToRemove: string, parentGuid: string) {
    const parent = this.frontendConditions.find(g => g.guid === parentGuid)

    if (parent) {
      parent.value = parent.value.filter(c => c.guid !== guidToRemove)

      if (!parent.value.length) {
        this.frontendConditions = this.frontendConditions.filter(g => g.guid !== parent.guid)
      }
    }
  }

  editCondition(newCondition: IterableListItem<ConditionBuilder>, parentGuid: string) {
    const parent = this.frontendConditions.find(g => g.guid === parentGuid)

    if(parent) {
      const conditionIndex = parent.value.findIndex(c => c.guid === newCondition.guid)

      parent.value.splice(conditionIndex, 1, newCondition)
    }
  }

  addCondition(model: ConfigCondition, parentGuid?: string) {
    const builder = ConditionsBuilderFactory.getCondition(model.type)

    builder.setBuilderModel(model)

    if (parentGuid && this.frontendConditions.length) {
      const parent = this.frontendConditions.find(g => g.guid === parentGuid)

      if (parent) {

        parent.value.push(this.iterableItemFactory.create(builder))
      }
    } else {
      this.frontendConditions.push(this.iterableItemFactory.create([ this.iterableItemFactory.create(builder) ]))
    }
  }
}
