var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"filter-journal mt-5",attrs:{"title":_vm.$t('filter_title_journal_setting')}},[(_vm.journal_moderation_all_ban && _vm.journal_moderation_all_warn)?_c('a-alert',{attrs:{"type":"warning","show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('global_journal_warn_ban_enabled_message'))},slot:"message"})]):_vm._e(),_c('switch-input',{class:{ 'mt-0': !(_vm.journal_moderation_all_ban && _vm.journal_moderation_all_warn) },attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'journalBan',
        'hasAnchor': true,
        'disabled': _vm.journal_moderation_all_ban || (_vm.journal_moderation_all_ban && _vm.journal_moderation_all_warn)
      }
    }}}),(_vm.journal_moderation_all_ban && !_vm.journal_moderation_all_warn)?_c('a-alert',{attrs:{"type":"warning","show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('global_journal_ban_enabled_message'))},slot:"message"})]):_vm._e(),_c('switch-input',{class:{ 'mb-0': !(_vm.journal_moderation_all_warn && !_vm.journal_moderation_all_ban) },attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'journalWarn',
        'hasAnchor': true,
        'disabled': _vm.journal_moderation_all_warn || (_vm.journal_moderation_all_ban && _vm.journal_moderation_all_warn)
      }
    }}}),(_vm.journal_moderation_all_warn && !_vm.journal_moderation_all_ban)?_c('a-alert',{attrs:{"type":"warning","show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('global_journal_warn_enabled_message'))},slot:"message"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }