import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromXpCondition from "./ResolveFromXpCondition";

export default class ResolveFromXpConditionBuilder extends ConditionBuilder {
  model!: ResolveFromXpCondition;

  icon = 'exposure';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');
}
