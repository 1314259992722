import ConditionBuilder from "../../../Builders/ConditionBuilder";
import TopicsCondition from "./TopicsCondition";

export default class TopicsConditionBuilder extends ConditionBuilder {
  model!: TopicsCondition;

  icon = 'forum';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/TopicsCondition/TopicsConditionView.vue');
}
