import { DisabledAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export function DisabledActionModel(): DisabledAction {
  return {
    type: PunishActionTypesEnum.Disabled
  }
}

export class DisabledActionBuilder extends BaseBuilderClass {
  model = DisabledActionModel()

  icon = 'remove_moderator'

  view?: VueComponent | undefined

  constructor(data?: DisabledAction) {
    super()

    if (data) {
      this.model = data
    }
  }
}
