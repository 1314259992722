import { ModuleConditionTypeEnum } from "@/includes/logic/Modules/types/types";
import TopicsConditionBuilder from "../models/conditions/TopicsCondition/TopicsConditionBuilder";
import ZoneConditionBuilder from "../models/conditions/ZoneCondition/ZoneConditionBuilder";
import UsersConditionBuilder from "../models/conditions/UsersCondition/UsersConditionBuilder";
import WorkTimeConditionBuilder from "../models/conditions/WorkTimeCondition/WorkTimeConditionBuilder";
import ResolveFromRankConditionBuilder from "../models/conditions/ResolveFromRankCondition/ResolveFromRankConditionBuilder";
import ResolveFromXpConditionBuilder from "../models/conditions/ResolveFromXpCondition/ResolveFromXpConditionBuilder";
import ResolveFromRepConditionBuilder from "../models/conditions/ResolveFromRepCondition/ResolveFromRepConditionBuilder";
import ResolveFromApConditionBuilder from "../models/conditions/ResolveFromApCondition/ResolveFromApConditionBuilder";
import BoostsConditionBuilder from "../models/conditions/BoostsCondition/BoostsConditionBuilder";
import UserTypeConditionBuilder from "../models/conditions/UserType/UserTypeConditionBuilder";
import MessageContentTypeConditionBuilder from "../models/conditions/MessageContentTypeCondition/MessageContentTypeConditionBuilder";

export default class ConditionsBuilderFactory {
  static getCondition(conditionType: ModuleConditionTypeEnum) {
    switch (conditionType) {
      case ModuleConditionTypeEnum.Topics:
        return new TopicsConditionBuilder()
      case ModuleConditionTypeEnum.Zone:
        return new ZoneConditionBuilder()
      case ModuleConditionTypeEnum.Users:
        return new UsersConditionBuilder()
      case ModuleConditionTypeEnum.WorkTime:
        return new WorkTimeConditionBuilder()
      case ModuleConditionTypeEnum.ResolveFromRank:
        return new ResolveFromRankConditionBuilder()
      case ModuleConditionTypeEnum.ResolveFromXp:
        return new ResolveFromXpConditionBuilder()
      case ModuleConditionTypeEnum.ResolveFromRep:
        return new ResolveFromRepConditionBuilder()
      case ModuleConditionTypeEnum.ResolveFromAp:
        return new ResolveFromApConditionBuilder()
      case ModuleConditionTypeEnum.Boosts:
        return new BoostsConditionBuilder()
      case ModuleConditionTypeEnum.UserType:
        return new UserTypeConditionBuilder()
      case ModuleConditionTypeEnum.MessageContentType:
        return new MessageContentTypeConditionBuilder()
    }
  }
}
