import { PunishActions } from "@/includes/logic/Modules/models/PunishActions/types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { cloneDeep, snakeCase } from "lodash";

export abstract class BaseBuilderClass {
  abstract model: PunishActions

  abstract icon: string;

  abstract view?: VueComponent

  updateModel(model: PunishActions) {
    this.model = cloneDeep(model)
  }

  actionTitleKey() {
    if (this.model) {
      return `punish_action_type_${ snakeCase(this.model.type) }`
    }

    return ''
  }

  actionHelpMessageKey() {
    if (this.model) {
      return `${ this.actionTitleKey() }_help_message`
    }

    return ''
  }

  previewActionTitleKey() {
    return this.actionTitleKey()
  }
}
