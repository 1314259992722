



















































































































import { isMessageLikeAction } from "@/includes/logic/Modules/models/PunishActions/utils/typeGuards";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";
import { TagData } from "piramis-base-components/src/components/Tags/types";

import { Component, Emit, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";
import PeriodSimplifierMixin from "piramis-base-components/src/components/PeriodSimplifier/PeriodSimplifierMixin";

@Component({
  components: {
    ConfigField,
    Icon
  },
})
export default class PunishActionPreview extends Mixins(PeriodSimplifierMixin) {
  @Prop() action!: BaseBuilderClass | null

  @Prop({ type: Boolean, default: true, required: false }) hasDisableButtonAction!: boolean

  @Prop({ type: Boolean, default: true, required: false }) hasTitle!: boolean

  @Prop() titleKey!: string

  @Prop({ type: Boolean, default: true }) bordered!: boolean

  @Prop({ type: Boolean, default: true, required: false }) hasRemoveButton!: boolean

  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  @Prop() tags!: Array<TagData> | null

  @Prop({ type: Boolean, default: false, required: false }) hasEditButton!: boolean

  @Emit()
  onDisableActionClick() {
    return
  }

  @Emit()
  onEditClick() {
    return this.action?.model?.type
  }

  @Emit()
  onRemoveClick() {
    return this.action?.model && this.action.model.type
  }

  get miniHelpMessage() {
    if (this.titleKey) {
      return this.$te(`field_${ this.titleKey }_mini_help_message`) ? this.$t(`field_${ this.titleKey }_mini_help_message`).toString() : null
    }

    return this.$te(`field_punish_action_mini_help_message`) ? this.$t(`field_punish_action_mini_help_message`).toString() : null

  }

  get helpMessage() {
    if (this.titleKey) {
      return this.$te(`field_${ this.titleKey }_help_message`) ? this.$t(`field_${ this.titleKey }_help_message`).toString() : null
    }

    return this.$te(`field_punish_action_help_message`) ? this.$t(`field_punish_action_help_message`).toString() : null
  }

  // *******************************************************************
  get unitOptions(): Array<number> {
    return [ StepUnit.Second, StepUnit.Minute, StepUnit.Hour, StepUnit.Day, StepUnit.Week, StepUnit.Month ]
      .map((unit: StepUnit) =>  unit / StepUnit.Second)
  }

  get unitLabelTranslation() {
    if (this.action) {
      const { model } = this.action

      if (this.currentUnit && (model.type === PunishActionTypesEnum.Ban || model.type === PunishActionTypesEnum.Mute || model.type === PunishActionTypesEnum.MuteMedia)) {
        return this.unitLabel(this.currentUnit, model.time)
      }
    }

    return ''
  }

  @Watch('action.model.time', { immediate: true })
  onChange(value:number | undefined) {
    if (typeof value === 'number') {
      this.calculateCurrentUnit(this.unitOptions, value, this.unitOptions[0])
    }
  }

  // *******************************************************************

  get actionTitle() {
    if (this.action) {
      const { model } = this.action

      if (this.currentUnit && (model.type === PunishActionTypesEnum.Ban || model.type === PunishActionTypesEnum.Mute || model.type === PunishActionTypesEnum.MuteMedia)) {
        if (model.time > 0) {
          return this.$t(this.action.previewActionTitleKey() + "_with_unit", [ `${ this.$t('on_value_title') } ${ model.time / this.currentUnit } ${ this.unitLabelTranslation }` ])
        }

        return this.$t(this.action.previewActionTitleKey() + "_with_unit", [  this.$t('punish_time_infinity').toString().toLowerCase() ])
      }

      return this.$t(this.action.previewActionTitleKey())
    }

    return null
  }

  get messageIfExists() {
    if (this.action?.model) {
      const { model } = this.action

      if (isMessageLikeAction(model)) {
        const { message } = model

        if (message.length) {
          return message[0].text.trim().replaceAll("\n", "</br>")
        }

        return this.$t('punish_action_message_not_set').toString()
      }

      return null
    }

    return null
  }
}
