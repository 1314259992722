import { MuteMediaAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

import { cloneDeep, snakeCase } from "lodash";

export function MuteMediaActionModel(): MuteMediaAction {
  return cloneDeep({
    type: PunishActionTypesEnum.MuteMedia,
    delete_message: true,
    delete_message_delay: 0,
    time: 600,
    message: []
  })
}

export class MuteMediaActionBuilder extends BaseBuilderClass {
  model = MuteMediaActionModel()
  icon = 'media_bluetooth_off'
  view = () => import('@/components/Modules/punish-actions/MuteMediaActionView.vue');

  constructor(data?: MuteMediaAction) {
    super()

    if (data) {
      this.model = data
    }
  }

  previewActionTitleKey() {
    if (this.model.delete_message) {
      return `punish_action_type_${ snakeCase(this.model.type) }_with_delete`
    }

    return `punish_action_type_${ snakeCase(this.model.type) }_without_delete`
  }
}
