import { getTariffTranslationKey } from "@/includes/helpers/tariffHelper";
import { ADVANCED_TAG, BRAND_TAG, STARTER_TAG, ULTIMATE_TAG } from "@/includes/constants";

import { TagData } from "piramis-base-components/src/components/Tags/types";

import { Vue, Component } from 'vue-property-decorator'
import { capitalize } from 'lodash';

@Component
export default class TariffsTagsHelper extends Vue {
  getTagsByFieldKey(key: string): Array<TagData> | null {
    if (this.$store.state.chatState.chat && this.$store.state.chatState.chat.limits) {
      if (!this.$store.state.chatState.chat.limits.options.includes(key)) {
        return [ {
          'text': this.$t(getTariffTranslationKey((this as any).$getOptionTariff(key))) as string,
          'color': this.$store.state.themeConfig.getTariff(this.$t(getTariffTranslationKey((this as any).$getOptionTariff(key)))).color
        } ]
      }

      return null
    }

    return null
  }

  getTagByLicenceKey(licenseType: "starter" | "advanced" | "ultimate"): Array<TagData> {
    const keys = Object.keys(this.$store.state.themeConfig.config.tariffs);

    return [
      {
        color: this.$store.state.themeConfig.config.tariffs[licenseType].color,
        text: capitalize(keys.find(k => k === licenseType))
      }
    ]
  }

  get brandTag() {
    if (this.$store.getters.isChatSet && (!this.$store.getters.isChatLicenseExists || !this.$store.getters.getHaveBrand || !this.$store.getters.getHaveLicense)) {
      return BRAND_TAG
    } else {
      return null
    }
  }

  get advancedLicenseTag(): Array<TagData> | null {
    return this.$store.getters.isChatSet && (!this.$store.getters.isChatAtLeastAdvanced || !this.$store.getters.isChatLicenseExists) ? ADVANCED_TAG : null
  }

  get starterLicenseTag(): Array<TagData> | null {
    return this.$store.getters.isChatSet && !this.$store.getters.isChatLicenseExists ? STARTER_TAG : null
  }

  get ultimateLicenseTag(): Array<TagData> | null {
    return this.$store.getters.isChatSet && (!this.$store.getters.isChatLicenseExists || !this.$store.getters.getHaveLicense) ? ULTIMATE_TAG : null
  }
}
