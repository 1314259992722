import { KickAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

import { cloneDeep, snakeCase } from "lodash";

export function KickActionModel(): KickAction {
  return cloneDeep({
    type: PunishActionTypesEnum.Kick,
    delete_message: true,
    delete_message_delay: 0,
    message: []
  })
}

export class KickActionBuilder extends BaseBuilderClass {
  model = KickActionModel()
  icon = 'logout'
  view = () => import('@/components/Modules/punish-actions/KickActionView.vue');

  constructor(data?: KickAction) {
    super()

    if (data) {
      this.model = data
    }
  }

  previewActionTitleKey() {
    if (this.model.delete_message) {
      return `punish_action_type_${ snakeCase(this.model.type) }_with_delete`
    }

    return `punish_action_type_${ snakeCase(this.model.type) }_without_delete`
  }
}
