



































import { ConfigConditionTypes } from '@/includes/logic/Modules/ConfigConditionTypes'
import ConditionsBuilderFactory from '@/includes/logic/Modules/Factories/ConditionsBuilderFactory'
import ConfigCondition from '@/includes/logic/Modules/models/ConfigCondition'
import { ModuleConditionTypeEnum } from '@/includes/logic/Modules/types/types'
import ConditionBuilder from "@/includes/logic/Modules/Builders/ConditionBuilder";
import { IterableListItem } from "@/components/TriggerSetup/logic/types/types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";
import UsersConditionValue from "@/components/Modules/conditions/UsersCondition/UsersConditionValue.vue";

import { TagData } from "piramis-base-components/src/components/Tags/types";
import WizardLikeSelect from "piramis-base-components/src/components/WizardLikeSelect/WizardLikeSelect.vue";
import { WizardLikeList, IWizardLikeListItem } from "piramis-base-components/src/components/WizardLikeSelect/types";
import WizardLikeListItems from 'piramis-base-components/src/components/WizardLikeSelect/WizardLikeListItems.vue';

import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'

@Component({
  components: {
    UsersConditionValue,
    WizardLikeSelect,
    WizardLikeListItems,
  }
})
export default class SelectCondition extends Mixins(TariffsTagsHelper) {
  @VModel() isSelectConditionModalOpen!: boolean

  @Prop() conditionsTypes!: Array<ModuleConditionTypeEnum>

  @Prop() disabled!: boolean

  @Prop() currentCondition!: IterableListItem<ConditionBuilder> | null

  @Prop() usersInfoService!: GetUserInfoServiceImpl

  @Emit()
  conditionSelected(condition: ConfigCondition) {
    return condition
  }

  @Emit()
  resetState() {
    return null
  }

  @Watch('currentCondition')
  onCurrentConditionChange(value: IterableListItem<ConditionBuilder> | null) {
    if (value) {
      this.currentKey = value.value.model.type
    } else {
      this.currentKey = value
    }
  }

  isModalOpen = false

  currentBuilder: ConditionBuilder | null = null

  currentKey: ModuleConditionTypeEnum | null = null

  get listData(): WizardLikeList {
    const items = ConfigConditionTypes
      .filter(c => c.name === ModuleConditionTypeEnum.Topics ? this.$store.getters.isChatForum : true)
      .filter(c => this.conditionsTypes ? this.conditionsTypes.includes(c.name) : true)
      .reduce((acc: Array<IWizardLikeListItem>, value) => {
        const builder = ConditionsBuilderFactory.getCondition(value.name)
        let tag: Array<TagData> | null = null

        builder.setBuilderModel(new value.value())

        if (value.name === ModuleConditionTypeEnum.ResolveFromRank) {
          tag = this.getTagsByFieldKey('extended_reputation')
        }

        if (builder) {
          acc.push({
            key: value.name,
            title: this.$t(builder.model.titleKey).toString(),
            helpMessage: this.$te(builder.model.helpMessageKey) ? this.$t(builder.model.helpMessageKey).toString() : null,
            icon: builder.icon,
            tag,
            disabled: {
              state: builder.isDisabled
            }
          })
        }

        return acc
      }, [])

    return [
      {
        type: "Simple",
        items
      }
    ]
  }

  setCurrentBuilder(type: ModuleConditionTypeEnum | null) {
    if (!type) {
      this.currentBuilder = type
    } else {
      const condition = ConfigConditionTypes.find(c => c.name === type)

      if (condition) {
        const builder = ConditionsBuilderFactory.getCondition(type)

        if (builder) {
          if (this.currentCondition && type === this.currentCondition.value.model.type) {
            builder.setBuilderModel(this.currentCondition.value.model)
          } else {
            builder.setBuilderModel(new condition.value())
          }

          this.currentBuilder = builder
        }
      }
    }
  }

  isSaving = false

  async saveItem(_: IWizardLikeListItem['key'] | null, closeModalCallback: () => void) {
    if (this.currentBuilder) {
      this.isSaving = true

      try {
        const res = await this.currentBuilder.model.validate()

        if (res) {
          this.conditionSelected(this.currentBuilder.model)

          closeModalCallback()
        }
      } finally {
        this.isSaving = false
      }
    }
  }
}
