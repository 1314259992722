









import TopicsCondition from '@/includes/logic/Modules/models/conditions/TopicsCondition/TopicsCondition'
import BlackWhiteListTags from '../../components/BlackWhiteListTags.vue'
import { ForumTopic } from "@/includes/types/Chat/types";

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    BlackWhiteListTags
  }
})
export default class TopicsConditionValue extends Vue {
  @Prop() model!: TopicsCondition

  get frontendTopics() {
    const value = { ...this.model.values }
    const chat = this.$store.state.chatState.chat

    value.items = this.model.values.items.map(id => {
      if (chat && chat.topics) {
        const topic = chat.topics.find((t: ForumTopic) => t.id === id)

        if (topic) {
          return topic.title
        }

        return id
      }
    })

    return value
  }
}

