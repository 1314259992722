import ConditionBuilder from "@/includes/logic/Modules/Builders/ConditionBuilder";
import BoostsCondition from "./BoostsCondition";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export default class BoostsConditionBuilder extends ConditionBuilder {
  icon: string = 'volunteer_activism';
  model!: BoostsCondition;
  view: VueComponent = () => import('../../../../../../components/Modules/conditions/BoostsCondition/BoostsConditionView.vue');
}
