import { BanActionBuilder } from "../models/PunishActions/actions/BanAction";
import { DeleteMessageActionBuilder } from "../models/PunishActions/actions/DeleteMessageAction";
import { DisabledActionBuilder } from "@/includes/logic/Modules/models/PunishActions/actions/DisabledAction";
import { IncrementalActionBuilder } from "../models/PunishActions/actions/IncrementalAction";
import { KickActionBuilder } from "../models/PunishActions/actions/KickAction";
import { MuteActionBuilder } from "../models/PunishActions/actions/MuteAction";
import { MuteMediaActionBuilder } from "../models/PunishActions/actions/MuteMediaAction";
import { NotifyActionBuilder } from "../models/PunishActions/actions/NotifyAction";
import { PunishActionTypesEnum } from "../models/PunishActions/types";
import { LogActionBuilder } from "../models/PunishActions/actions/LogAction";

export const FactoryBuilderClass = function () {
  const getBuilder = (type: PunishActionTypesEnum) => {
    switch (type) {
      case PunishActionTypesEnum.Disabled:
        return new DisabledActionBuilder()
      case PunishActionTypesEnum.Notify:
        return new NotifyActionBuilder()
      case PunishActionTypesEnum.DeleteMessage:
        return new DeleteMessageActionBuilder()
      case PunishActionTypesEnum.MuteMedia:
        return new MuteMediaActionBuilder()
      case PunishActionTypesEnum.Mute:
        return new MuteActionBuilder()
      case PunishActionTypesEnum.Ban:
        return new BanActionBuilder()
      case PunishActionTypesEnum.Kick:
        return new KickActionBuilder()
      case PunishActionTypesEnum.Incremental:
        return new IncrementalActionBuilder()
      case PunishActionTypesEnum.Log:
        return new LogActionBuilder()
    }
  }

  return {
    getBuilder
  }
}()
