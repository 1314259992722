











import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    Accordion
  }
})
export default class ModuleConfigSettingsWrapper extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) hasConditions!: boolean

  isOpen = false

  created() {
    this.isOpen = this.hasConditions
  }
}
