










































import PunishActionPreview from "@/components/Modules/punish-actions/components/PunishActionPreview.vue";
import { PunishActions, PunishActionTypesEnum } from '@/includes/logic/Modules/models/PunishActions/types'
import { FactoryBuilderClass } from "@/includes/logic/Modules/Factories/PunishActionsBuilderFactory";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { DisabledActionModel } from "@/includes/logic/Modules/models/PunishActions/actions/DisabledAction";
import { PunishActionEditorButtonsKey, PunishActionEditorPlaceholdersKey, PunishActionEditorPrefix } from "@/includes/logic/Modules/di";
import { InputSetups } from "@/mixins/input-setups";

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import {
  PlaceholdersOnes,
  PlaceholdersStack
} from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";
import { TagData } from "piramis-base-components/src/components/Tags/types";
import WizardLikeSelect from "piramis-base-components/src/components/WizardLikeSelect/WizardLikeSelect.vue";
import { IWizardLikeListItem, WizardLikeList } from "piramis-base-components/src/components/WizardLikeSelect/types";

import { Component, Mixins, Prop, Provide, VModel } from 'vue-property-decorator';
import { cloneDeep, groupBy, } from "lodash";

@Component({
  data() {
    return {
      PunishActionTypesEnum
    }
  },
  components: {
    WizardLikeSelect,
    PunishActionPreview,
    ConfigField,
    Icon
  }
})
export default class PunishActionSetting extends Mixins(InputSetups) {
  @VModel() action!: PunishActions

  @Prop() punishActionTypes!: Array<PunishActionTypesEnum>

  @Prop() titleKey!: string

  @Prop() prefix!: string

  @Provide(PunishActionEditorPrefix) prefixProvide = this.prefix

  @Prop() tags!: Array<TagData> | null | undefined

  @Prop() messageEditorPlaceholders!: PlaceholdersOnes | PlaceholdersStack

  @Provide(PunishActionEditorPlaceholdersKey) placeholders = this.messageEditorPlaceholders

  @Prop() messageEditorButtons!: PlaceholdersOnes | PlaceholdersStack

  @Provide(PunishActionEditorButtonsKey) buttons = this.messageEditorButtons

  get disabled() {
    return (this.tags !== undefined && this.tags !== null)
  }

  get footerDisableButtonTitle() {
    const base = 'punish_action_modal_disable_button_title'

    if (this.prefix) {
      return this.$t(`${ this.prefix }${ base }`)
    }

    return this.$t(base)

  }

  get vModelActionBuilder() {
    const builder = FactoryBuilderClass.getBuilder(this.action.type)

    if (builder) {
      builder.updateModel(this.action)

      return builder
    }

    return null
  }

  punishActionsBuilders = Object.keys(PunishActionTypesEnum)
    .filter(c => this.punishActionTypes ? this.punishActionTypes.includes(c as PunishActionTypesEnum) : true)
    .reduce((acc: Array<BaseBuilderClass>, value) => {
      const builder = FactoryBuilderClass.getBuilder(value as PunishActionTypesEnum)

      if (builder) {
        acc.push(builder)
      }

      return acc
    }, [])

  currentBuilder: BaseBuilderClass | null = null

  currentKey: PunishActionTypesEnum | null = null

  isModalActive = false

  setActionToNone() {
    this.action = DisabledActionModel()
    this.isModalActive = false
  }

  onEditClick(type: PunishActionTypesEnum | undefined) {
    if (type) {
      if (type === PunishActionTypesEnum.Disabled) {
        this.isModalActive = true
      }

      this.currentKey = type
    }
  }

  setCurrentBuilder(type: PunishActionTypesEnum | null) {
    if (!type) {
      this.currentBuilder = type
    } else {
      const builder = cloneDeep(this.punishActionsBuilders.find(b => b.model && b.model.type === type)) ?? null

      if (type === this.action.type) {
        if (builder) {
          builder.updateModel(this.action)

          this.currentBuilder = builder
        }
      } else {
        this.currentBuilder = builder
      }
    }

    if (type === PunishActionTypesEnum.Log) {
      this.saveItem()
      this.isModalActive = false
    }
  }

  saveItem() {
    if (this.currentBuilder) {
      this.action = cloneDeep(this.currentBuilder.model)
    }
  }

  get listData(): WizardLikeList {
    const items: Partial<Record<PunishActionTypesEnum, Array<IWizardLikeListItem>>> = groupBy(this.punishActionsBuilders
      .map(b => {
        return {
          key: b.model.type,
          title: this.$t(b.actionTitleKey()).toString(),
          helpMessage: this.$te(b.actionHelpMessageKey()) ? this.$t(b.actionHelpMessageKey()).toString() : null,
          icon: b.icon
        }
      }), 'key')

    return [
      {
        type: "Grouped",
        title: this.$t('punish_action_types_base_group_title').toString(),
        items: [
          ...items?.Ban ?? [],
          ...items?.Mute ?? [],
          ...items?.Kick ?? [],
        ]
      },
      {
        type: "Simple",
        items: [
          ...items?.Notify ?? [],
          ...items?.Log ?? [],
          ...items?.DeleteMessage ?? [],
          ...items?.MuteMedia ?? [],
          ...items?.Incremental ?? []
        ]
      }
    ]

  }
}
