import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ZoneCondition from "./ZoneCondition";

export default class ZoneConditionBuilder extends ConditionBuilder {
  model!: ZoneCondition;

  icon = 'animation';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ZoneCondition/ZoneConditionView.vue');
}
