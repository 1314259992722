import UserProfileApi from "@/includes/Api/UserProfile.api";

import GetUserInfoService from "piramis-base-components/src/shared/logic/getUsersInfo/GetUserInfoService";
import { GetUserInfoRes } from "piramis-base-components/src/shared/logic/getUsersInfo/types";

export default class GetUserInfoServiceImpl extends GetUserInfoService {
  getUsersInfoReq(users: Array<number | string>): Promise<{ data: Array<GetUserInfoRes> }> {
    return UserProfileApi.getUsersInfo(+this.targetId, users)
        .then(res => res.data)
  }
}
