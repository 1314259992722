import { IncrementalAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { FactoryBuilderClass } from "@/includes/logic/Modules/Factories/PunishActionsBuilderFactory";

import { cloneDeep } from "lodash";

export function IncrementalActionModel(): IncrementalAction {
  return cloneDeep({
    type: PunishActionTypesEnum.Incremental,
    actions: []
  })
}

export class IncrementalActionBuilder extends BaseBuilderClass {
  model = IncrementalActionModel()
  icon = 'stacked_line_chart'
  view = () => import('@/components/Modules/punish-actions/IncrementalActionView.vue');

  constructor(data?: IncrementalAction) {
    super()

    if (data) {
      this.model = data
    }
  }

  getModelTypeBuilders() {
    return (this.model.actions ?? []).reduce((acc: Array<BaseBuilderClass>, val) => {
      const b = FactoryBuilderClass.getBuilder(val.type)

      b.updateModel(val)

      acc.push(b)

      return acc
    }, [])
  }
}
