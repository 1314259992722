import { NotifyAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

import { cloneDeep } from "lodash";

export function NotifyActionModel(): NotifyAction {
  return cloneDeep({
    type: PunishActionTypesEnum.Notify,
    message: []
  })
}

export class NotifyActionBuilder extends BaseBuilderClass {
  model = NotifyActionModel()

  icon = 'warning_amber'

  view = () => import('@/components/Modules/punish-actions/NotifyActionView.vue');

  constructor(data?: NotifyAction) {
    super()

    if (data) {
      this.model = data
    }
  }
}
