












































import { InputSetups } from "@/mixins/input-setups";

import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types';
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, Prop, Mixins, PropSync } from 'vue-property-decorator';
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    TimeUnitInput,
    SwitchInput,
    NestedContent,
    ConfigField
  }
})
export default class FilterResetPunishSettings extends Mixins(InputSetups) {
  @PropSync('allowedInterval', { type: Number, required: true }) allowed_interval!: number

  @PropSync('allowedIntervalRound', { type: Boolean, required: true }) allowed_interval_round!: boolean

  @Prop() fieldsPrefix!: string

  get prefix() {
    if (this.fieldsPrefix) {
      return `${ this.fieldsPrefix }_`
    }

    return ''
  }
}
