import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromApCondition
  from "@/includes/logic/Modules/models/conditions/ResolveFromApCondition/ResolveFromApCondition";

export default class ResolveFromApConditionBuilder extends ConditionBuilder {
  model!: ResolveFromApCondition;

  icon = 'exposure';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');
}
