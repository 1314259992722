import ConditionBuilder from "../../../Builders/ConditionBuilder";
import MessageContentTypeCondition from "./MessageContentTypeCondition";

export default class MessageContentTypeConditionBuilder extends ConditionBuilder {
  model!: MessageContentTypeCondition;

  icon = 'message';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/MessageContentTypeCondition/MessageContentTypeConditionView.vue');
}
