










































































import CommonFilterSettings from "@/includes/logic/Modules/models/filters/CommonFilterSettings";
import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";
import { InputSetups } from "@/mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    NestedContent,
    PunishActionSetting,
    SwitchInput,
    TimeUnitInput,
    NumberInput,
  }
})
export default class FilterRemoveEditedSetting extends Mixins(InputSetups, Placeholders) {
  @VModel() settings!: CommonFilterSettings
}
