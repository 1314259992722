import { BasePunishActionModel, MessagePunishAction, PunishActionTypesEnum } from "../types"

export function isMessageLikeAction(action: BasePunishActionModel | MessagePunishAction): action is MessagePunishAction {
  const punishActionTypesWithMessage: Array<PunishActionTypesEnum> = [
    PunishActionTypesEnum.Ban,
    PunishActionTypesEnum.Notify,
    PunishActionTypesEnum.Mute,
    PunishActionTypesEnum.MuteMedia,
    PunishActionTypesEnum.DeleteMessage,
    PunishActionTypesEnum.Kick,
  ]

    return punishActionTypesWithMessage.includes(action.type)
}
