var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"filter-common-settings mt-5",attrs:{"title":_vm.$t('filter_title_common_rules')}},[_c('switch-input',{staticClass:"mt-0",class:{ 'mb-0': !_vm.settings.enabled_common_filter },attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.settings,
        'key': 'enabled_common_filter',
        'hasAnchor': true,
      }
    }}}),(_vm.settings.enabled_common_filter)?_c('nested-content',{staticClass:"mb-0"},[(_vm.isFloodCommonSettings)?_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'can_mute_media_when_possibly',
          'hasAnchor': true,
        }
      }}}):_vm._e(),_c('number-input',{class:{ 'mt-0': !_vm.isFloodCommonSettings },attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'allowed_count',
          'prefix': _vm.prefix,
          'hasAnchor': true,
          'min': 0,
          'settingWrapper': {
            value: 0,
            hasFieldButton: true,
            customiseValue: 3
          }
        }
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'notice_before_ban_count',
          'hasAnchor': true,
          'min': 0,
          'settingWrapper': {
            disabledValue: 0,
            hasFieldButton: true,
            customiseValue: 3
          }
        }
      }}}),(_vm.settings.notice_before_ban_count > 0)?_c('punish-action-setting',{staticClass:"punish-action-nested",attrs:{"prefix":"warning_punish_action_","title-key":"warning_punish_action","message-editor-placeholders":_vm.FILTERS_PLACEHOLDERS},model:{value:(_vm.settings.warning_punish_action),callback:function ($$v) {_vm.$set(_vm.settings, "warning_punish_action", $$v)},expression:"settings.warning_punish_action"}}):_vm._e(),(_vm.settings.notice_before_ban_count > 0)?_c('a-alert',{staticClass:"mb-2",attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('filter_warning_message_comments_alert'))},slot:"message"})]):_vm._e(),_c('punish-action-setting',{staticClass:"punish-action-nested",attrs:{"prefix":"ban_punish_action_","title-key":"ban_punish_action","message-editor-placeholders":_vm.FILTERS_PLACEHOLDERS,"message-editor-buttons":_vm.newFilterEditorButtons},model:{value:(_vm.settings.ban_punish_action),callback:function ($$v) {_vm.$set(_vm.settings, "ban_punish_action", $$v)},expression:"settings.ban_punish_action"}}),_c('a-divider',[_vm._v(" "+_vm._s(_vm.$t('filter_subsetting_accordion_reset_punish'))+" ")]),_c('filter-reset-punish-settings',{attrs:{"allowed-interval":_vm.settings.allowed_interval,"allowed-interval-round":_vm.settings.allowed_interval_round,"fields-prefix":_vm.fieldsPrefix},on:{"update:allowedInterval":function($event){return _vm.$set(_vm.settings, "allowed_interval", $event)},"update:allowed-interval":function($event){return _vm.$set(_vm.settings, "allowed_interval", $event)},"update:allowedIntervalRound":function($event){return _vm.$set(_vm.settings, "allowed_interval_round", $event)},"update:allowed-interval-round":function($event){return _vm.$set(_vm.settings, "allowed_interval_round", $event)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }