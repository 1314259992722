import ConditionBuilder from "../../../Builders/ConditionBuilder";
import UsersCondition from "./UsersCondition";

export default class UsersConditionBuilder extends ConditionBuilder {
  model!: UsersCondition;

  icon = 'group';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/UsersCondition/UsersConditionView.vue');
}
