import ConditionBuilder from "../../../Builders/ConditionBuilder";
import WorkTimeCondition from "./WorkTimeCondition";

export default class WorkTimeConditionBuilder extends ConditionBuilder {
  model!: WorkTimeCondition;

  icon = 'access_time';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/WorkTimeCondition/WorkTimeConditionView.vue');
}
