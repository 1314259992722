































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfig from '@/includes/logic/Modules/models/ModuleConfig'
import SelectCondition from '@/components/Modules/components/conditions/SelectCondition.vue'
import ConditionsConfigService from '@/includes/logic/Modules/ConditionsConfigService'
import ZoneConditionValue from '@/components/Modules/conditions/ZoneCondition/ZoneConditionValue.vue'
import ZoneCondition from '@/includes/logic/Modules/models/conditions/ZoneCondition/ZoneCondition'
import WorkTimeConditionValue from '@/components/Modules/conditions/WorkTimeCondition/WorkTimeConditionValue.vue'
import WorkTimeCondition from '@/includes/logic/Modules/models/conditions/WorkTimeCondition/WorkTimeCondition'
import ResolveFromConditionValue from '@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionValue.vue'
import ResolveFromCondition from '@/includes/logic/Modules/models/conditions/ResolveFromCondition'
import UsersConditionValue from '@/components/Modules/conditions/UsersCondition/UsersConditionValue.vue'
import UsersCondition from '@/includes/logic/Modules/models/conditions/UsersCondition/UsersCondition'
import TopicsConditionValue from '@/components/Modules/conditions/TopicsCondition/TopicsConditionValue.vue'
import TopicsCondition from '@/includes/logic/Modules/models/conditions/TopicsCondition/TopicsCondition'
import ConfigCondition from '@/includes/logic/Modules/models/ConfigCondition'
import { IterableListItem } from '@/components/TriggerSetup/logic/types/types'
import ConditionBuilder from '@/includes/logic/Modules/Builders/ConditionBuilder'
import { ModuleConditionTypeEnum } from '@/includes/logic/Modules/types/types'
import BoostsConditionValue from "@/components/Modules/conditions/BoostsCondition/BoostsConditionValue.vue";
import BoostsCondition from "@/includes/logic/Modules/models/conditions/BoostsCondition/BoostsCondition";
import UserTypeCondition from "@/includes/logic/Modules/models/conditions/UserType/UserTypeCondition";
import UserTypeConditionValue from "@/components/Modules/conditions/UserTypeCondition/UserTypeConditionValue.vue";
import ConditionsBuilderFactory from "@/includes/logic/Modules/Factories/ConditionsBuilderFactory";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import MessageContentTypeConditionValue
  from "@/components/Modules/conditions/MessageContentTypeCondition/MessageContentTypeConditionValue.vue";
import MessageContentTypeCondition
  from "@/includes/logic/Modules/models/conditions/MessageContentTypeCondition/MessageContentTypeCondition";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    MessageContentTypeConditionValue,
    SwitchInput,
    UserTypeConditionValue,
    BoostsConditionValue,
    TopicsConditionValue,
    UsersConditionValue,
    ResolveFromConditionValue,
    WorkTimeConditionValue,
    ZoneConditionValue,
    SelectCondition,
    Icon,
  },
  data() {
    return {
      ZoneCondition,
      WorkTimeCondition,
      ResolveFromCondition,
      UsersCondition,
      TopicsCondition,
      BoostsCondition,
      UserTypeCondition,
      MessageContentTypeCondition
    }
  }
})
export default class ModuleConfigSettings extends Mixins(UseFields, InputSetups, TariffsTagsHelper) {
  @VModel() config!: ModuleConfig

  @Prop() conditionsTypes!: Array<ModuleConditionTypeEnum>

  @Prop() disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false }) showTransparent!: boolean

  isSelectConditionModalOpen = false

  conditionsConfigService: ConditionsConfigService | null = null

  currentCondition: IterableListItem<ConditionBuilder> | null = null;

  currentParentGuid: string | null = null

  @Watch('conditionsConfigService.frontendConditions', { deep: true })
  onFrontendConditionChange() {
    if (this.conditionsConfigService) {
      this.config.conditions = this.conditionsConfigService.makeCleanConditions()
    }
  }

  handleConditionSelected(condition: ConfigCondition, parentGuid: string) {
    if (this.currentParentGuid) {
      if (this.currentCondition) {
        if (this.currentCondition.value.model.type === condition.type) {
          const builderCopy = cloneDeep(this.currentCondition)

          builderCopy.value.setBuilderModel(condition)

          this.editCondition(builderCopy, parentGuid)
        } else {
          const builder = ConditionsBuilderFactory.getCondition(condition.type)

          if (builder) {
            builder.setBuilderModel(condition)

            this.editCondition(this.conditionsConfigService!.iterableItemFactory.create(builder), parentGuid)
          }
        }
      }
    } else {
      this.addCondition(condition, parentGuid)
    }
  }

  resetConditionsState() {
    this.currentParentGuid = null;
    this.currentCondition = null;
  }

  removeCondition(guidToRemove: string, parentGuid: string) {
    if (this.conditionsConfigService) {
      this.conditionsConfigService.removeCondition(guidToRemove, parentGuid)
    }
  }

  setConditionForEdit(condition: IterableListItem<ConditionBuilder>, parentGuid: string) {
    this.currentParentGuid = parentGuid
    this.currentCondition = cloneDeep(condition)
  }

  editCondition(condition: IterableListItem<ConditionBuilder>, parentGuid: string) {
    if (this.conditionsConfigService) {
      this.conditionsConfigService.editCondition(condition, parentGuid)
    }
  }

  addCondition(condition: ConfigCondition, parentGuid?: string) {
    if (this.conditionsConfigService) {
      this.conditionsConfigService.addCondition(condition, parentGuid)
    }

    this.isSelectConditionModalOpen = false
  }

  created() {
    this.conditionsConfigService = new ConditionsConfigService(this.config.conditions)
  }
}
