import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromRepCondition from "./ResolveFromRepCondition";

export default class ResolveFromRepConditionBuilder extends ConditionBuilder {
  model!: ResolveFromRepCondition;

  icon = 'thumb_up';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');
}
