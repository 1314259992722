















import MessageContentTypeCondition
  from "@/includes/logic/Modules/models/conditions/MessageContentTypeCondition/MessageContentTypeCondition";
import SelectOptionsMixin from "../../../../mixins/SelectOptionsMixin";

import getVSelectOptionByValue from 'piramis-js-utils/lib/getVSelectOptionByValue'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  data() {
    return {
      getVSelectOptionByValue
    }
  }
})
export default class MessageContentTypeConditionValue extends Mixins(SelectOptionsMixin) {
  @Prop() model!: MessageContentTypeCondition
}

