






import WorkTimeCondition from '@/includes/logic/Modules/models/conditions/WorkTimeCondition/WorkTimeCondition'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'

@Component({})
export default class WorkTimeConditionValue extends Vue {
  @Prop() model!: WorkTimeCondition

  get label() {
    return `C ${ moment(this.model.from, 'HH:mm:ss').format('HH:mm') } по ${ moment(this.model.to, 'HH:mm:ss').format('HH:mm') }`
  }
}
