import { IMessageSettings } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Body/logic/types";
import { MessageMedia } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types";
import { MessageButtons } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types";

export enum PunishActionTypesEnum {
  Disabled = 'Disabled',
  Notify = 'Notify',
  // UnMute = 'UnMute',
  // UnBan = 'UnBan',
  DeleteMessage = 'DeleteMessage',
  MuteMedia = 'MuteMedia',
  Mute = 'Mute',
  Ban = 'Ban',
  Kick = 'Kick',
  // PreventiveMute = 'PreventiveMute',
  // ReservedBan = 'ReservedBan',

  Incremental = 'Incremental',
  Log = 'Log'
}

export type BasePunishActionModel = {
  type: PunishActionTypesEnum
}

export type MessagePunishAction = {
  message: Array<IMessageSettings & MessageMedia & MessageButtons>
} & BasePunishActionModel

// BanAction
export type BanAction = MessagePunishAction & {
  type: PunishActionTypesEnum.Ban

  delete_message: boolean
  delete_message_delay: number
  time: number
}

// DeleteMessageAction
export type DeleteMessageAction = MessagePunishAction & {
  type: PunishActionTypesEnum.DeleteMessage

  delete_message_delay: number
}

// DisabledAction
export type DisabledAction = BasePunishActionModel & {
  type: PunishActionTypesEnum.Disabled
}

// IncrementalAction
export type IncrementalAction = BasePunishActionModel & {
  type: PunishActionTypesEnum.Incremental

  actions: Array<PunishActions> | null
}

// KikAction
export type KickAction = MessagePunishAction & {
  type: PunishActionTypesEnum.Kick

  delete_message: boolean
  delete_message_delay: number
}

// MuteAction
export type MuteAction = MessagePunishAction & {
  type: PunishActionTypesEnum.Mute

  delete_message: boolean
  delete_message_delay: number
  time: number
}

// MuteMediaAction
export type MuteMediaAction = MessagePunishAction & {
  type: PunishActionTypesEnum.MuteMedia

  delete_message: boolean
  delete_message_delay: number
  time: number
}

// NotifyAction
export type NotifyAction = MessagePunishAction & {
  type: PunishActionTypesEnum.Notify
}

// LogAction
export type LogAction = BasePunishActionModel & {
  type: PunishActionTypesEnum.Log
}

export type PunishActions = BanAction |
  DeleteMessageAction |
  DisabledAction |
  IncrementalAction |
  KickAction |
  MuteAction |
  MuteMediaAction |
  NotifyAction |
  LogAction
