
















import { BlackWhiteListItem, BlackWhiteListItemType } from 'piramis-base-components/src/components/BlackWhiteList/types'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class BlackWhiteListTags extends Vue {

  @Prop() values!: BlackWhiteListItem<number | string>

  @Prop() formatter!: (tag: string) => string

  get tagColorByListType() {
    return this.values.type === BlackWhiteListItemType.Blacklist ? 'rgba(var(--a-danger), 1)' : 'rgba(var(--a-success), 1)'
  }

  formatTag(tag: string | number | null) {
    if (typeof this.formatter === "function" && !!tag) {
      return this.formatter(tag.toString())
    }

    return tag
  }
}
