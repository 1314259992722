import { LogAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { cloneDeep, snakeCase } from "lodash";

export function LogActionModel(): LogAction {
  return cloneDeep({
    type: PunishActionTypesEnum.Log,
  })
}

export class LogActionBuilder extends BaseBuilderClass {
  model = LogActionModel()
  icon = 'book'
  view = undefined

  constructor(data?: LogAction) {
    super()

    if (data) {
      this.model = data
    }
  }
  //
  previewActionTitleKey() {
    return `punish_action_type_${ snakeCase(this.model.type) }`
  }
}
