






import ZoneCondition from '@/includes/logic/Modules/models/conditions/ZoneCondition/ZoneCondition'
import { ZoneTypeEnum } from '@/includes/logic/Modules/types/types'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({})
export default class ZoneConditionValue extends Vue {

  @Prop() model!: ZoneCondition

  get label() {
    return this.zoneTypeOptions.find(o => o.value === this.model.value)?.label ?? ''
  }

  get zoneTypeOptions() {
    return Object.values(ZoneTypeEnum)
      .map((v) => ({
        label: this.$t(`zone_type_${ snakeCase(v.toString()) }_option`).toString(),
        value: v
      }))
  }
}
