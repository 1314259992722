import ConditionBuilder from "../../../Builders/ConditionBuilder";
import ResolveFromRankCondition
  from "@/includes/logic/Modules/models/conditions/ResolveFromRankCondition/ResolveFromRankCondition";
import store from "@/store/store";

export default class ResolveFromRankConditionBuilder extends ConditionBuilder {
  model!: ResolveFromRankCondition;

  icon = 'military_tech';

  view:ConditionBuilder['view'] = () => import('@/components/Modules/conditions/ResolveFromCondition/ResolveFromConditionView.vue');

  isDisabled() {
    return store.getters.isChatAtLeastAdvanced && !store.getters.isChatLicenseExists
  }
}
