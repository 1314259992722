var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-reset-punish-setting"},[_c('time-unit-input',{staticClass:"mt-0",class:{ 'mb-0': _vm.allowed_interval < 1 },attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'allowed_interval',
        'prefix': _vm.prefix,
        'clearable': false,
        'hasAnchor': true,
        'units': [ _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week ],
        'step': _vm.StepUnit.Minute,
        'min': 0,
        'settingWrapper': {
          customiseValue: _vm.StepUnit.Minute,
          hasFieldButton: true,
          disabledValue: 0
        }
      }
    }}}),(_vm.allowed_interval > 0)?_c('nested-content',{staticClass:"mb-0"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'allowed_interval_round',
          'hasAnchor': true,
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }