






import UserTypeCondition from "@/includes/logic/Modules/models/conditions/UserType/UserTypeCondition";
import { UserTypeEnum } from "@/includes/logic/Modules/models/conditions/UserType/types";

import { Component, Prop, Vue } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({})
export default class UserTypeConditionValue extends Vue {
  @Prop() model!: UserTypeCondition

  get label() {
    if (this.model.value === UserTypeEnum.IsPremium) {
      return this.$t(`user_type_condition_value_${ snakeCase(UserTypeEnum.IsPremium) }`).toString()
    } else {
      return this.$t(`user_type_condition_value_${ snakeCase(UserTypeEnum.IsNotPremium) }`).toString()
    }
  }
}
