import { DeleteMessageAction, PunishActionTypesEnum } from "@/includes/logic/Modules/models/PunishActions/types";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

import { cloneDeep } from "lodash";

export function DeleteMessageActionModel(): DeleteMessageAction {
  return cloneDeep({
    type: PunishActionTypesEnum.DeleteMessage,
    delete_message_delay: 0,
    message: []
  })
}

export class DeleteMessageActionBuilder extends BaseBuilderClass {
  model = DeleteMessageActionModel()
  icon = 'comments_disabled'
  view = () => import('@/components/Modules/punish-actions/DeleteMessageActionView.vue');

  constructor(data?: DeleteMessageAction) {
    super()

    if (data) {
      this.model = data
    }
  }
}
