import ConfigCondition from "@/includes/logic/Modules/models/ConfigCondition";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { Exclude } from "class-transformer";
import { cloneDeep } from "lodash";

export default abstract class ConditionBuilder {
  abstract model: ConfigCondition

  @Exclude()
  abstract view: VueComponent

  /**
   * material icon
   **/
  @Exclude()
  abstract icon: string

  isDisabled() {
    return false
  }

  setBuilderModel(conditionData: ConfigCondition) {
    this.model = cloneDeep(conditionData)
  }
}
