var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"filter-remove-edited-setting"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.settings,
        'key': 'process_edit_separately',
        'hasAnchor': true,
      }
    }}}),(_vm.settings.process_edit_separately)?_c('nested-content',{staticClass:"mb-0"},[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'remove_edited_timeout',
          'hasAnchor': true,
          'min': 0,
          'step': _vm.StepUnit.Minute,
          'units': [_vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day],
          'settingWrapper': {
            disabledValue: 0,
            hasFieldButton: true,
            customiseValue: _vm.StepUnit.Minute
          }
        }
      }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'remove_edited_msg',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 10,
            hasFieldButton: true,
            disabledValue: 0,
          }

        }
      }}}),_c('punish-action-setting',{staticClass:"punish-action-nested",attrs:{"title-key":"remove_edited_punish_action","message-editor-placeholders":_vm.FILTERS_PLACEHOLDERS},model:{value:(_vm.settings.remove_edited_punish_action),callback:function ($$v) {_vm.$set(_vm.settings, "remove_edited_punish_action", $$v)},expression:"settings.remove_edited_punish_action"}})],1):_c('a-alert',{attrs:{"show-icon":"","type":"warning","message":_vm.$t('field_process_edit_separately_disabled_alert_message')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }